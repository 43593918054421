import { FC, ReactElement, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { InfoOutlined } from '@mui/icons-material'
import { Box, Button, Grid, Typography } from '@mui/material'

import CustomTooltip from '~/components/common/customTooltip/CustomTooltip'
import { InvoiceDetailsData } from '~/types/InvoiceDetailsData.model'
import { OrderDetailsData } from '~/types/OrderDetailsData.model'
import CustomModal from '~/UI/modals/CustomModal'
import ReturnConfirmModal from '~/UI/modals/modalBodies/returnModalBody/ReturnConfirmModal'
import { TEXTS } from '~/utils/allConstants/Constants'

import DataGrid from '../DataGrid/DataGrid'
import useDataGridStore, { DataGridProvider } from '../DataGrid/DataGridContext'

import AssetSpecificInformation, {
  AssetSpecificContent,
} from './components/AssetSpecificInformation'
import Balance from './components/Balance'
import Controls from './components/Controls'

import variables from '~/theme/scss/_variables.module.scss'

const { RETURN, NO_DATA_FOUND } = TEXTS
const { acrColorDarkGrey, acrColorMediumGrey } = variables

type Fields = {
  id: string
  label: string
  minWidth: number
}

type Options = {
  id: string
  name: string
  queryName: string
  tableProps: { fields: Fields[] }
}

type Props = {
  data: InvoiceDetailsData | OrderDetailsData
  options: Options
  assetSpecificContent: AssetSpecificContent[]
  handlePdfExport: () => void
  roleCheck?: boolean
}

export const TitleTypography: FC<{ title: string; infoText?: string }> = ({
  title,
  infoText,
  ...props
}): ReactElement => (
  <Typography
    color={(theme) => theme.palette.text.secondary}
    sx={{ display: 'flex', gap: '10px', alignItems: 'center', marginBottom: 2 }}
    {...props}
  >
    <Box sx={{ fontWeight: 500, fontSize: 20 }}>{title}</Box>
    {infoText && (
      <CustomTooltip title={infoText}>
        <InfoOutlined
          color={'primary'}
          sx={{ cursor: 'pointer', fontSize: '20px' }}
        />
      </CustomTooltip>
    )}
  </Typography>
)
export const MainTypography: FC<{ text: string }> = ({
  text,
}): ReactElement => (
  <Typography
    sx={{
      color: acrColorDarkGrey,
      fontWeight: 700,
    }}
  >
    {text}
  </Typography>
)
export const SecondaryTypography: FC<{
  children: string
  component?: string
}> = ({ children, ...props }): ReactElement => (
  <Typography
    sx={{ color: acrColorMediumGrey, wordWrap: 'break-word' }}
    {...props}
  >
    {children}
  </Typography>
)

const DetailsPage: FC<Props> = ({
  data,
  options,
  assetSpecificContent,
  handlePdfExport,
  roleCheck = true,
}): ReactElement => {
  const source = useCallback(() => {
    return { items: data.orderItems }
  }, [data])

  const navigate = useNavigate()
  const { setTableProps, tableProps } = useDataGridStore()
  const [openReturnConfirmModal, setOpenReturnConfirmModal] = useState(false)

  useEffect(() => {
    setTableProps({
      name: `${options.queryName}-${options.id}`,
      source,
      ...options.tableProps,
    })
  }, [source, data, options, setTableProps])

  const handleNavigateReturn = () => {
    navigate(`/returns/${data.orderNumber}/details`)
  }

  const handleReturn = () => {
    setOpenReturnConfirmModal((prev) => {
      return !prev
    })
  }

  const closeModal = () => {
    setOpenReturnConfirmModal((prev) => {
      return !prev
    })
  }

  return (
    <>
      <Box sx={{ px: { xs: 2, md: 3 } }}>
        <Controls
          isData={!!data}
          handlePdfExport={handlePdfExport}
          roleCheck={roleCheck}
        />
        {!!data ? (
          <Box mt={3}>
            <Grid container sx={[!roleCheck && { mb: 4 }]}>
              <Grid item xs={9}>
                <Typography
                  variant="h1"
                  sx={{
                    color: (theme) => theme.palette.text.secondary,
                    fontWeight: 'bold',
                  }}
                >
                  {options.name}
                </Typography>
              </Grid>
              <Grid item xs={3} className="action-buttons-wrapper">
                {data.isReturnable && (
                  <Button
                    className={'action-button'}
                    variant={'contained'}
                    onClick={handleReturn}
                  >
                    {RETURN}
                  </Button>
                )}
              </Grid>
            </Grid>
            {roleCheck && <Balance balance={data.balance} />}
            <AssetSpecificInformation
              assetSpecificContent={assetSpecificContent}
              data={data}
            />
            <Box sx={{ marginBottom: '20px' }}>
              {Object.keys(tableProps).length > 0 && <DataGrid />}
            </Box>
          </Box>
        ) : (
          <Box sx={{ height: 300 }}>
            <Typography
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
              }}
            >
              {NO_DATA_FOUND}
            </Typography>
          </Box>
        )}
      </Box>
      <CustomModal
        open={openReturnConfirmModal}
        setOpen={setOpenReturnConfirmModal}
        sx={{ width: '50%' }}
      >
        <ReturnConfirmModal
          closeModal={closeModal}
          onClick={handleNavigateReturn}
        />
      </CustomModal>
    </>
  )
}
// eslint-disable-next-line react/display-name
export default (props: Props) => (
  <DataGridProvider>
    <DetailsPage {...props} />
  </DataGridProvider>
)
