import Link from '@mui/material/Link'

import { Fields } from '~/types/Fields.model'
import { TEXTS } from '~/utils/allConstants/Constants'
import { formatCurrency } from '~/utils/helpers/helperFunctions'

const { ITEM_NO, DESCRIPTION, PRICE, AMOUNT, SHIPPED } = TEXTS

export const fields: Fields[] = [
  {
    id: 'shippedCount',
    label: SHIPPED,
    minWidth: 50,
    format: (value: number) => (value !== null ? value : '--'),
  },
  {
    id: 'unitOfMeasure',
    label: 'U/M',
    minWidth: 50,
  },
  {
    id: 'itemNo',
    label: ITEM_NO,
    minWidth: 80,
    format: (value, row) =>
      row.productInfoUrl ? (
        <Link
          href={row.productInfoUrl}
          target={'_blank'}
          className="cursor-pointer"
        >
          {value}
        </Link>
      ) : (
        value
      ),
  },
  {
    id: 'description',
    label: DESCRIPTION,
    minWidth: 200,
  },
  {
    id: 'price',
    label: PRICE,
    minWidth: 100,
    format: (value: number) => formatCurrency(value),
  },
  {
    id: 'amount',
    label: AMOUNT,
    minWidth: 100,
    format: (value: number) => formatCurrency(value),
  },
]

type Address = {
  city: string
  state: string
  postcode: string
}

type Bill = {
  billToLocation: string
  shipToLocation: string
}

export const invoiceSpecificContent = [
  {
    id: 'billShipSection',
    items: [
      {
        title: 'Billing Location',
        dataId: 'billingAddress',
        id: 'billingAddress',
        fields: [
          {
            id: 'billToLocation',
          },
          { id: 'billToName' },
          { id: 'addressLine1' },
          { id: 'addressLine2' },
          { id: 'addressLine3' },
          {
            id: 'city',
            format: ({ city, state, postcode }: Address) =>
              `${city}, ${state} ${postcode}`,
          },
          { id: 'country' },
        ],
      },
      {
        title: 'Shipping Location',
        dataId: 'shippingAddress',
        id: 'shippingAddress',
        fields: [
          {
            id: 'billToLocation',
            format: ({ billToLocation, shipToLocation }: Bill) =>
              `${billToLocation}${shipToLocation ? `-${shipToLocation}` : ''}`,
          },
          { id: 'shipToName' },
          { id: 'addressLine1' },
          { id: 'addressLine2' },
          { id: 'addressLine3' },
          {
            id: 'city',
            format: ({ city, state, postcode }: Address) =>
              `${city}, ${state} ${postcode}`,
          },
          { id: 'country' },
        ],
      },
    ],
  },
  {
    id: 'invoiceOrderDetails',
    items: [
      {
        title: 'Order Details',
        id: 'orderDetails',
        fields: [
          { name: 'Invoice Number', id: 'invoiceNumber' },
          { name: 'Order Number', id: 'orderNumber' },
          { name: 'Customer PO', id: 'customerPO' },
          { name: 'Order Taker', id: 'orderTaker' },
        ],
      },
      {
        title: 'Shipping Details',
        id: 'shippingDetails',
        fields: [
          { name: 'Date Ordered', id: 'orderDate' },
          { name: 'Date Shipped', id: 'shippingDate' },
          { name: 'Carrier', id: 'shipper' },
          { name: 'Tracking Number', id: 'trackingNumber' },
          { name: 'Shipped from', id: 'shippingLocation' },
        ],
      },
      {
        title: 'Payment Details',
        id: 'paymentDetails',
        fields: [
          { name: 'Payment Terms', id: 'paymentTerms' },
          {
            name: 'Invoice Due Date',
            id: 'dueDate',
            format: ({ dueDate }: { dueDate: string }) =>
              `${dueDate ? dueDate : '-'}`,
          },
        ],
      },
    ],
  },
]
