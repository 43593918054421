import { AxiosResponse } from 'axios'
import { format, parseISO } from 'date-fns'
import { v4 as uuid } from 'uuid'
import * as yup from 'yup'

import axiosInstance from '~/services/axiosInstance'
import { getPreparedFilters } from '~/services/filters-utils'
import { Fields } from '~/types/Fields.model'
import {
  InvoiceFilter,
  OrderFilter,
  PriceFilter,
  UserFilter,
} from '~/types/Filter.model'
import { PageParams } from '~/types/PageParams.model'
import { SelectedInternalReason } from '~/types/Return.model'

import { ApprovalStatusItems, Method, TEXTS } from '../allConstants/Constants'

import 'yup-phone-lite'

const {
  EMAIL,
  USER_TYPE,
  FIRST_NAME,
  LAST_NAME,
  MOBILE_NUMBER,
  COMPANY_NAME,
  COMPANY_PHONE_NUMBER,
  AREA_OF_RESPONSIBILITY,
  EMAIL_FORMAT_MESSAGE,
  NO_PASSWORD_MESSAGE,
  PASSWORDS_MATCH_MESSAGE,
  WAREHOUSE_MISPICK,
  INVALID_FIELD_MESSAGE,
  REQUIRED_FIELD_MESSAGE,
} = TEXTS

type groupsApplied = {
  id: string
  name: string
  groupType: string
}[]

export const passwordValidation = (value: string, { createError }: any) => {
  value = value || ''
  const res: Array<string> = []
  const specialChars: any = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/

  if (value.toUpperCase() == value) {
    res.push('1 lowercase character')
  }
  if (value.toLowerCase() == value) {
    res.push('1 uppercase character')
  }
  if (specialChars.test(value) == false) {
    res.push('1 special character')
  }
  if (/[0-9]/.test(value) == false) {
    res.push('1 number')
  }
  if (value.length < 6) {
    res.push("have a minimum length of '6' characters")
  }
  if (res.length == 0) return true
  let error_message: string =
    ' Password is not valid! It must contain at least ' + res[0]
  for (let i = 1; i < res.length - 1; i++) {
    error_message += `, ${res[i]}`
  }
  if (res.length > 1) {
    error_message += ` and ${res[res.length - 1]}`
  }
  return createError({ message: error_message })
}

export const convertPageToLink = (page: string) =>
  `${page.charAt(0).toLowerCase()}${page
    .slice(1)
    .replace(/\s/g, '-')
    .toLowerCase()}`

export const applyResponseGrouping = (
  dataResponse: any,
  groupsApplied: groupsApplied
) => {
  if (!!dataResponse?.items) {
    const modifiedResponse: {
      key: string
      groupLabel: string
      groupItemName: any
      type: string
      rowSx?: { borderLeft: string }
    }[] = []
    if (groupsApplied.length > 1) {
      const { name: groupLabelOuter } = groupsApplied.find(
        (groupApplied) => groupApplied.groupType === 'outer'
      )
      const { name: groupLabelInner } = groupsApplied.find(
        (groupApplied) => groupApplied.groupType === 'inner'
      )
      Object.entries(dataResponse.items).forEach(([key, value]: any) => {
        modifiedResponse.push({
          key: uuid(),
          groupLabel: groupLabelOuter,
          groupItemName: key,
          type: 'group',
        })
        Object.entries(value).forEach(([innerKey, innerValue]: any) => {
          modifiedResponse.push({
            key: uuid(),
            groupLabel: groupLabelInner,
            groupItemName: innerKey,
            type: 'group',
            rowSx: { borderLeft: '15px solid white' },
          })
          modifiedResponse.push(...innerValue)
        })
      })
    } else {
      Object.entries(dataResponse.items).forEach(([key, value]: any) => {
        const { name: groupLabel } = groupsApplied[0]
        modifiedResponse.push({
          key: uuid(),
          groupLabel: groupLabel,
          groupItemName: key,
          type: 'group',
        })
        modifiedResponse.push(...value)
      })
    }
    return { ...dataResponse, items: modifiedResponse }
  } else {
    return dataResponse
  }
}

export const exportFile = ({
  name,
  extension,
  exportfilename,
  id,
  filters,
  fields,
}: {
  name: string
  extension: string
  exportfilename: string
  id?: string | undefined
  filters?: PageParams<InvoiceFilter | OrderFilter | PriceFilter | UserFilter>
  fields?: Fields[]
}) => {
  const Url = () => {
    if (extension === 'pdf') {
      return `/v1/acr/${name}/${id}/export`
    } else if (extension === 'zip') {
      return `/v1/${name}/${id}/assets`
    } else {
      return `/v1/acr/${name}/export`
    }
  }
  const url = Url()

  const fileName = `${exportfilename}.${extension}`
  if (filters) {
    filters = getPreparedFilters(filters, fields)
  }
  return downloadFile(url, fileName, filters, Method.GET)
}

export const downloadFile = (
  url: string,
  filename: string,
  params?: PageParams<InvoiceFilter | OrderFilter | PriceFilter | UserFilter>,
  method?: string,
  data?: { expandedProductId: number[] }
) => {
  return axiosInstance({
    url: url,
    method: method,
    params,
    responseType: 'blob', // important
    data: method == Method.POST ? data : undefined,
  }).then((response) => downloadFileRes({ response, filename }))
}

export const downloadFileRes = ({
  response,
  filename,
}: {
  response: AxiosResponse
  filename: string
}) => {
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename) //or any other extension
  document.body.appendChild(link)
  link.click()
}

export const castNullValues = (objectToCast: any) => {
  Object.keys(objectToCast).forEach((key) => {
    if (!objectToCast[key]) {
      objectToCast[key] = ''
    }
  })
  return objectToCast
}

export const formatCurrency = (number: number) => {
  const formatCurrencyOptions = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }
  const formatter = new Intl.NumberFormat('en-US', formatCurrencyOptions)
  return formatter.format(number)
}

export const formatDate = (
  value: string | number,
  option?: Intl.DateTimeFormatOptions
) => {
  if (value !== null && value !== '' && value !== undefined) {
    // new Date(value) creates date with UTC time while toLocaleString prints date adjusted for timezone of user,
    // which leads to off by one dates. normalize to not adjust for timezone.
    const dateUtc = new Date(value)
    const dateLocal = new Date(
      dateUtc.getTime() + Math.abs(dateUtc.getTimezoneOffset() * 60000)
    )

    if (dateLocal.getFullYear() === 9999) {
      return '-'
    }

    return dateLocal.toLocaleDateString('en-US', option != undefined && option)
  }
  return '-'
}

export const formatDateTo = (date: string) => {
  const formatDateData = format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
  const slicedDate =
    formatDateData.length > 10 ? formatDateData.slice(0, 10) : formatDateData
  const formattedDate = format(parseISO(slicedDate), 'yyyy-MM-dd')
  // Note - As per backend requirement.
  return `${formattedDate}T00:00:00.000Z`
}

export const fileValidation = (
  files: File[],
  { createError }: yup.TestContext
) => {
  if (files) {
    const fileArr: File[] = Array.from(files)
    let totalSize = 0
    for (let i = 0; i < fileArr.length; i++) {
      const size = fileArr[i].size / (1024 * 1024)
      const type = fileArr[i].type.split('/')[1]
      const validTypes = ['pdf', 'jpeg', 'png', 'jpg', 'gif']
      totalSize = totalSize + size
      if (!validTypes.includes(type)) {
        return createError({ message: 'File is not of supported type' })
      }
      if (size > 10) {
        return createError({ message: 'File exceeds 10MB' })
      }
      if (totalSize > 50) {
        return createError({
          message: 'Total Files size cannot be exceeds 50MB',
        })
      }
    }
    if (files.length > 10) {
      return createError({ message: 'Maximum 10 files allowed' })
    }
  }
  return true
}

export const hasChildren = (item: any) => {
  const { menuItem: children } = item

  if (children === undefined) {
    return false
  }

  if (children.constructor !== Array) {
    return false
  }

  if (children.length === 0) {
    return false
  }

  return true
}

export const toTitleCase = (phrase: string) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const trimStringValues = (obj: any) => {
  if (typeof obj === 'string') {
    return obj.trim()
  } else if (typeof obj === 'object' && !Array.isArray(obj)) {
    const trimmedObj: any = {}
    Object.keys(obj).forEach((key) => {
      trimmedObj[key] = trimStringValues(obj[key])
    })
    return trimmedObj
  } else if (Array.isArray(obj)) {
    return obj.map((item) => (typeof item === 'string' ? item.trim() : item))
  } else {
    return obj
  }
}

export const emptyDeep = (
  mixedVar: any,
  emptyValues = ['', null, undefined]
) => {
  let i, len
  for (i = 0, len = emptyValues.length; i < len; i++) {
    if (mixedVar === emptyValues[i]) {
      return true
    }
    if (Array.isArray(mixedVar)) {
      if (mixedVar.includes(emptyValues[i]) || mixedVar.length < 2) {
        return true
      }
    }
  }

  if (typeof mixedVar === 'object') {
    for (const item of Object.values(mixedVar)) {
      if (!emptyDeep(item, emptyValues) || item instanceof Date) {
        return false
      }
    }
    return true
  }
  return false
}

export const TableCellFormat = ({ column, row }: { column: any; row: any }) => {
  if (column.hasOwnProperty('format')) {
    return column.format(row[column.id], row)
  }

  return row[column.id]
}

const { APPROVED, DENIED } = ApprovalStatusItems

export const sendApprovalStatusInPayload = (approvalStatus: string) => {
  switch (true) {
    case approvalStatus === DENIED:
      return false
    case approvalStatus === APPROVED:
      return true
    default:
      return null
  }
}

export const getApprovalStatus = (value: boolean) => {
  switch (value) {
    case true:
      return APPROVED
    case false:
      return DENIED
    default:
      return ''
  }
}

export const getSelectedInternalReason = ({
  internalReasonCodes,
  internalReason,
}: SelectedInternalReason) =>
  internalReasonCodes && internalReason
    ? internalReasonCodes[internalReason as keyof typeof internalReasonCodes]
    : ''

export const isMisPickSelectedAsInternalReason = ({
  internalReasonCodes,
  internalReason,
}: SelectedInternalReason) =>
  getSelectedInternalReason({ internalReasonCodes, internalReason }) ===
  WAREHOUSE_MISPICK

export const removeNullUndefined = (obj) =>
  Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
WAREHOUSE_MISPICK

export const textFieldValidation = yup.string().default('')

export const emailValidation = yup
  .string()
  .default('')
  .trim()
  .email(EMAIL_FORMAT_MESSAGE)
  .required(REQUIRED_FIELD_MESSAGE(EMAIL))

export const responsibilityAreaValidation = yup
  .string()
  .default('')
  .required(REQUIRED_FIELD_MESSAGE(AREA_OF_RESPONSIBILITY))

export const userTypeValidation = yup
  .string()
  .default('')
  .required(REQUIRED_FIELD_MESSAGE(USER_TYPE))

export const firstNameValidation = yup
  .string()
  .default('')
  .trim()
  .required(REQUIRED_FIELD_MESSAGE(FIRST_NAME))

export const lastNameValidation = yup
  .string()
  .default('')
  .trim()
  .required(REQUIRED_FIELD_MESSAGE(LAST_NAME))

export const mobilePhoneRequiredValidation = yup
  .string()
  .phone('US', INVALID_FIELD_MESSAGE(MOBILE_NUMBER))
  .default('')
  .required(REQUIRED_FIELD_MESSAGE(MOBILE_NUMBER))

export const mobilePhoneValidation = yup
  .string()
  .phone('US', INVALID_FIELD_MESSAGE(MOBILE_NUMBER))
  .default('')
  .notRequired()

export const companyNameValidation = yup
  .string()
  .default('')
  .trim()
  .required(REQUIRED_FIELD_MESSAGE(COMPANY_NAME))

export const companyPhoneValidation = yup
  .string()
  .phone('US', INVALID_FIELD_MESSAGE(COMPANY_PHONE_NUMBER))
  .default('')
  .required(REQUIRED_FIELD_MESSAGE(COMPANY_PHONE_NUMBER))

export const passwordYupValidation = yup
  .string()
  .default('')
  .required(NO_PASSWORD_MESSAGE)
  .test('validation', 'error', passwordValidation)

export const confirmPasswordValidation = yup
  .string()
  .default('')
  .oneOf([yup.ref('password'), null], PASSWORDS_MATCH_MESSAGE)

export const confirmNewPasswordValidation = yup
  .string()
  .default('')
  .oneOf([yup.ref('newPassword'), null], PASSWORDS_MATCH_MESSAGE)
